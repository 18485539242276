<template>
  <!-- course Actions -->
  <div class="">
    <b-button
      v-if="userData.courses.find((e) => e.course === course._id) && !isExpired"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      class="btn-cart"
      :to="{
        name: 'courses-progress',
        params: { id: course._id },
      }"
    >
      <span>Complete Course</span>
    </b-button>
    <b-button
      v-else-if="
        !$store.state['cart'].cartItems.find((e) => e._id === course._id)
      "
      variant="primary"
      tag="a"
      class="btn-cart"
      @click="addInCart(course)"
    >
      <feather-icon icon="ShoppingCartIcon" class="mr-50" />
      <span v-if="!isExpired"> Add to Cart </span>
      <span v-if="isExpired" class=""> buy again your license is Expired </span>
    </b-button>
    <b-button
      v-else-if="
        $store.state['cart'].cartItems.find((e) => e._id === course._id)
      "
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      :to="{ name: 'checkout' }"
    >
      Checkout
    </b-button>
    <!-- <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      class="btn-cart"
      :to="{
        name: 'courses-progress',
        params: { id: course._id },
      }"
    >
      <span>Complete Course</span>
    </b-button> -->
  </div>
</template>

<script>
import {
  BFormFile,
  BForm,
  BFormGroup,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BButton,
  BFormInput,
  BModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { mapState, mapActions } from "vuex";
import cartApi from "@/apiServices/cartApi";

export default {
  components: {
    BFormFile,
    BForm,
    BFormGroup,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BButton,
    BFormInput,
    BModal,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
  },
  props: ["course"],
  data: () => ({
    userData: JSON.parse(localStorage.getItem("user")),
  }),
  computed: {
    isExpired() {
      // this.userData = JSON.parse(localStorage.getItem("user"));
      let students = this.course.students;
      // console.log(students);
      let StudentExpirationDate = students.find(
        (e) => e.student == this.userData._id
      );
      console.log(StudentExpirationDate?.expairationDate);
      console.log(
        new Date() > new Date(StudentExpirationDate?.expairationDate)
      );
      return new Date() > new Date(StudentExpirationDate?.expairationDate);
    },
  },
  methods: {
    async addToMyCart(course) {
      await cartApi.addToUsersCart(course._id).then((response) => {
        if (response.data) {
          console.log(response.data);
          this.items = response.data?.cartItems;
        } else {
        }
      });
    },
    ...mapActions("cart", ["addCourseInCart"]),
    async addInCart(course) {
      await this.addToMyCart(course);
      this.addCourseInCart(course);
    },
  },
};
</script>
