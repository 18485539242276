<template>
  <b-card class="ecommerce-card w-100" no-body>
    <div class="item-img text-center">
      <b-link
        :to="{
          name: 'preview-course',
          params: { id: course._id },
        }"
      >
        <b-img
          :alt="`${course.title}-${course._id}`"
          fluid
          class="card-img-top"
          style="height: 180px"
          :src="course.imageCover"
        />
      </b-link>
    </div>
    <!-- course Details -->
    <b-card-body border-variant="primary">
      <div class="item-wrapper">
        <b-row align-h="between">
          <b-card-text v-if="course.priceAfterDiscount" class="text-success">
            <b-badge variant="success" class="m-25">
              ${{ course.priceAfterDiscount }}
            </b-badge>
            <span
              style="
                text-decoration: line-through red;
                text-decoration-thickness: 1.5px;
              "
              class="text-secondary text-decoration-line-through"
            >
              {{ course.price }} $</span
            >
          </b-card-text>
          <h6 v-else class="item-price">${{ course.price }}</h6>
        </b-row>
      </div>
      <h6 class="item-name w-100">
        <b-link
          class="text-body item-name text-truncate d-inline-block"
          style="max-width: 190px"
          :to="{
            name: 'preview-course',
            params: { id: course._id },
          }"
        >
          {{ course.title }}
        </b-link>
      </h6>

      <b-card-text
        style="max-width: 290px !important"
        class="text-truncate d-inline-block item-description card-text item-description"
      >
        {{ course.description }}
      </b-card-text>
    </b-card-body>

    <!-- course Actions -->
    <div class="item-options text-center">
      <!-- <b-button
        variant="light"
        tag="a"
        class="btn-wishlist"
        @click="togglecourseInWishlist(course)"
      >
        <feather-icon
          icon="HeartIcon"
          class="mr-50"
          :class="{ 'text-danger': course.isInWishlist }"
        />
        <span>Wishlist</span>
      </b-button> -->
      <!-- <b-button
        variant="outline-secondary"
        class="btn-wishlist mr-0 mr-sm-25 mb-1 ml-0 mb-sm-0"
        :to="{
          name: 'preview-course',
          params: { id: course._id },
        }"
      >
        <span>View Course</span>
      </b-button> -->
      <courseButtons :course="course" />
    </div>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BForm,
  BFormGroup,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BButton,
  BFormInput,
  BModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { mapState, mapActions } from "vuex";
import cartApi from "@/apiServices/cartApi";
import courseButtons from "@/views/courses pages/components/coursebuttons.vue";

export default {
  components: {
    BFormFile,
    BForm,
    courseButtons,
    BFormGroup,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BButton,
    BFormInput,
    BModal,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
  },
  props: ["course"],
  data: () => ({
    userData: JSON.parse(localStorage.getItem("user")),
  }),
  methods: {
    async addToMyCart(course) {
      await cartApi.addToUsersCart(course._id).then((response) => {
        if (response.data) {
          console.log(response.data);
          this.items = response.data?.cartItems;
        } else {
        }
      });
    },
    ...mapActions("cart", ["addCourseInCart"]),
    async addInCart(course) {
      await this.addToMyCart(course);
      this.addCourseInCart(course);
    },
  },
};
</script>
