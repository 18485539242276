<template>
  <b-container>
    <b-row>
      <b-col md="3">
        <b-card-code title="Search" no-body>
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-row class="match-height mt-50"> </b-row>
            </div>
            <b-row class="w-100 mt-2">
              <b-col md="12">
                <h4 class="card-title">Categories</h4>
              </b-col>
              <b-col>
                <b-form-group label="" v-if="categories">
                  <b-form-radio-group
                    v-model="category"
                    :options="categories"
                    name=""
                    value-field="_id"
                    text-field="name"
                    stacked
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card-code>
      </b-col>
      <b-col md="9">
        <b-card-code title="All Courses " no-body>
          <b-card-body>
            <!-- <b-table
        v-if="courses.length > 0"
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :items="courses"
        :fields="fields"
      >
        <template #cell(imageCover)="data">
          <b-avatar :src="data.item.imageCover" />
        </template>
        <template #cell(Action)="data">
          <span>
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item :to="{ path: `/courses/${data.item._id}` }">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <duplicateCourse :item="data.item" />
                <deleteCourse :id="data.item._id" />
              </b-dropdown>
            </span>
          </span>
        </template>
      </b-table> -->
          </b-card-body>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <b-row class="w-100">
              <b-col md="6" lg="4" v-for="course in courses" :key="course._id">
                <courseCard :course="course" />
              </b-col>
            </b-row>
            <b-row>
              <div class="w-100">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="prams.limit"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-row>
          </b-card-body>

          <template #code>
            <!-- {{ codeKitchenSink }} -->
          </template>
        </b-card-code></b-col
      >
    </b-row>
  </b-container>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BContainer,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BFormRadioGroup,
} from "bootstrap-vue";
// import { codeKitchenSink } from "./code";
import coursesApi from "@/apiServices/coursesApi";
// import deleteCourse from "@/views/courses pages/delete course.vue";
// import duplicateCourse from "@/views/courses pages/duplicate.vue";
import courseCard from "@/views/courses pages/components/course card.vue";
import CategoriesApi from "@/apiServices/categoriesApi";

export default {
  components: {
    BFormRadioGroup,
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    courseCard,
    BContainer,
    // deleteCourse,
    // duplicateCourse,
  },
  data() {
    return {
      prams: {
        sort: "-createdAt",
        page: 1,
        limit: 10,
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "imageCover",
          label: "imageCover",
        },
        { key: "title", label: "Title" },
        { key: "category.name", label: "category" },
        { key: "sections.length", label: "sections" },
        { key: "students.length", label: "students" },
        "Action",
      ],
      categories: [],
      category: null,
      courses: [],
      /* eslint-disable global-require */
      status: [],
      //   codeKitchenSink,
    };
  },
  created() {
    this.getCategories();

    this.getCourses();
  },
  computed: {},
  mounted() {
    // this.totalRows = this.items.length;
  },
  watch: {
    currentPage() {
      this.prams.page = this.currentPage;
      this.getCourses();
    },
    category() {
      this.prams.page = 1;
      this.prams.category = this.category;
      this.getCourses();
    },
    filter() {
      this.prams.page = 1;
      this.prams.title = this.filter;
      this.getCourses();
    },
  },
  methods: {
    getCategories() {
      let params = {
        limit: 100,
      };
      CategoriesApi.getCategories(params).then((response) => {
        this.categories = [];

        if (response.data) {
          this.categories = response.data;
          this.categories.push({
            name: "All",
            _id: null,
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "get All Categories",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    deletecourseFromlist(id) {
      console.log("hi from parent " + id + "******" + this.courses.length);
      this.courses = this.courses.filter(function (obj) {
        return obj._id != id;
      });
    },
    getCourses() {
      Object.keys(this.prams).forEach((key) => {
        if (this.prams[key] === null || this.prams[key] === "") {
          delete this.prams[key];
        }
      });
      coursesApi.getCourses(this.prams).then((response) => {
        this.courses = [];
        if (response.data) {
          this.courses = response.data;
          this.currentPage = response.paginationResult?.currentPage;
          this.totalRows =
            response.paginationResult?.numberOfPages * this.prams.limit;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "get All Courses",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      //   this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
