import axiosApi from "../axios";
export default {
  async getCategories(params) {
    return await axiosApi
      .get(`/categories`, { params: params })
      .then((res) => {
        console.log(res.data + "this is data ");
        return res.data;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );

        return e.response?.data?.errors;
      });
  },
  async updateCategory(Category) {
    return await axiosApi
      .put(`/categories/${Category._id}`, Category)
      .then((res) => {
        console.log(res.data + "this is data ");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async createCategory(Category) {
    return await axiosApi
      .post(`/Categories`, Category)
      .then((res) => {
        console.log(res.data + "this is data ");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async deleteCategory(Category) {
    return await axiosApi
      .delete(`/Categories/${Category.id}`)
      .then((res) => {
        return res.status;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response;
      });
  },
};
